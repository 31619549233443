<template>
  <div
    aria-live="assertive"
    class="pointer-events-none fixed inset-0 z-20 flex items-end px-4 py-6 sm:items-start sm:p-6"
  >
    <div class="flex w-full flex-col items-center space-y-4 sm:items-end">
      <transition
        enter-active-class="transform ease-out duration-300 transition"
        enter-from-class="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
        enter-to-class="translate-y-0 opacity-100 sm:translate-x-0"
        leave-active-class="transition ease-in duration-100"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0"
      >
        <div
          v-if="show"
          class="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5"
        >
          <div class="p-4">
            <div class="flex items-start">
              <div class="flex-shrink-0">
                <CheckCircleIcon
                  v-if="type === 'success'"
                  class="h-6 w-6 text-green-400"
                  aria-hidden="true"
                ></CheckCircleIcon>
                <ExclamationTriangleIcon
                  v-if="type === 'warning'"
                  class="h-6 w-6 text-yellow-400"
                  aria-hidden="true"
                ></ExclamationTriangleIcon>
                <ExclamationCircleIcon
                  v-if="type === 'error'"
                  class="h-5 w-5 text-red-500"
                  aria-hidden="true"
                ></ExclamationCircleIcon>
              </div>
              <div class="ml-3 w-0 flex-1 pt-0.5">
                <p class="text-sm font-medium text-gray-900">{{ title }}</p>
                <p class="mt-1 text-sm text-gray-500">{{ message }}</p>
              </div>
              <div class="ml-4 flex flex-shrink-0">
                <button
                  type="button"
                  class="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-meevo-blue-500 focus:ring-offset-2"
                  @click="show = false"
                >
                  <span class="sr-only">Close</span>
                  <XMarkIcon class="h-5 w-5" aria-hidden="true"></XMarkIcon>
                </button>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script setup>
import { CheckCircleIcon, ExclamationCircleIcon, ExclamationTriangleIcon } from '@heroicons/vue/24/outline'
import { XMarkIcon } from '@heroicons/vue/20/solid'

const notification = useNotificationStore()
const show = ref(false)
const title = ref('')
const message = ref('')
const type = ref('')
let activeTimeout = -1

const duration = computed(() => {
  if (type.value === 'success') return 3000
  if (type.value === 'warning') return 4000
  return 5000 // error
})

const startTimeout = () => {
  clearTimeout(activeTimeout)
  const timeout = Number(duration.value)
  if (!show.value || timeout === -1) return
  activeTimeout = setTimeout(close, timeout)
}

notification.$subscribe((mutation, state) => {
  if (mutation.type === 'patch object') {
    title.value = state.title
    message.value = state.message
    type.value = state.type
    show.value = true
    startTimeout()
  }
})

const close = () => {
  show.value = false
}

watch(duration, startTimeout)

onMounted(() => {
  if (show.value) startTimeout()
})
</script>
