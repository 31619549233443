<template>
  <div class="flex h-full flex-col">
    <AppMenu />
    <div class="flex grow flex-col py-4 md:py-6">
      <main class="mx-auto flex w-full max-w-7xl grow flex-col px-4 sm:px-6 lg:px-8">
        <slot></slot>
      </main>
    </div>
    <AppFooter />
    <GlobalNotification />
  </div>
</template>
